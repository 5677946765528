<template>
  <a-card class="card" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelLayout="{ span: 8, gutter: 20 }"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getSystemPage"
      :deleteFun="deleteSystemInfo"
      :createFun="createSystemInfo"
      :updateFun="updateSystemInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { validateMoney, validateMobile, validateEmail } from '@/utils/validate'
import { getSystemPage, createSystemInfo, updateSystemInfo, deleteSystemInfo, getSystemInfoOption } from '@/api/system'
const levels = {
  1: { value: 1, name: '初级' },
  2: { value: 2, name: '中级' },
  3: { value: 3, name: '高级' },
  4: { value: 4, name: '公司管理员' },
  5: { value: 5, name: '超级管理员' }
}
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '用户姓名', name: 'name', fieldType: '', queryType: ''
        },
        {
          label: '部门', name: 'department', fieldType: 'select', options: this.GLOBAL.departmentMaps
        }
      ],
      // defaultQueryParams: {
      //   type: 0
      // },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '用户姓名',
          dataIndex: 'name'
        },
        {
          title: '账号',
          dataIndex: 'account'
        },
        {
          title: '级别',
          dataIndex: 'level',
          customRender: (text) => {
            return levels[text].name
          }
        },
        {
          title: '手机',
          dataIndex: 'mobile'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '地址',
          dataIndex: 'address'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入登陆账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码不能少于6位', trigger: 'blur' }
        ],
        mobile: [
          { validator: (rule, value) => { return validateMobile(value) }, message: '手机号格式不正确' }
        ],
        email: [
          { validator: (rule, value) => { return validateEmail(value) }, message: '邮箱格式不正确' }
        ],
        salary: [
          { validator: (rule, value) => { return validateMoney(value) }, message: '基本工资金额不合法' }
        ],
        price: [
          { validator: (rule, value) => { return validateMoney(value) }, message: '提成单价金额不合法' }
        ]
      },
      modelTitle: '用户',
      formFields: [
        {
          label: '用户姓名', name: 'name', type: 'default'
        },
        {
          label: '登陆账号', name: 'account', type: 'password'
        },
        {
          label: '密码', name: 'password', type: 'password'
        },
        {
          label: '角色', name: 'role_id_list', type: 'select', mode: 'multiple', options: [{ value: 4, name: '仓储部出库录单员' }], span: 16, func: getSystemInfoOption, params: 'role'
        },
        {
          label: '部门', name: 'department', type: 'select', options: this.GLOBAL.departmentMaps
        },
        {
          label: '级别', name: 'level', type: 'select', options: levels
        },
        {
          label: '手机号', name: 'mobile', type: 'default'
        },
        {
          label: '邮箱', name: 'email', type: 'default'
        },
        {
          label: '地址', name: 'address', type: 'default', span: 24
        },
        {
          label: '基本工资', name: 'salary', type: 'default'
        },
        {
          label: '提成单价', name: 'price', type: 'default'
        },
        {
          label: '备注', name: 'remark', type: 'textarea', span: 24
        }
      ],
      moduleName: 'user'
    }
  },
  methods: {
    getSystemPage,
    createSystemInfo,
    updateSystemInfo,
    deleteSystemInfo
  }
}
</script>
